import React from 'react';
import Link from '../components/Link';

export default ({
	path
}) => {
	return (
		<div className="grid-container contained">
			<div className="row align--center mt5">
				<div className="col c12 c10--lg">
					<div className="blog-share df rich-text line-break">
						<h5 className="dib sans--md fw--700">Share</h5>
						<Link
							title="Share this post to Twitter"
							aria-label="Share this post to Twitter"
							className="ml6"
							url={`https://twitter.com/intent/tweet?url=https://www.genexa.com${path}`}
							openInNewWindow={true}
						>
							<span className="blog-share-icon blog-share-icon--twitter"></span>
						</Link>
						<Link
							title="Share this post to Facebook"
							aria-label="Share this post to Facebook"
							className="ml6"
							url={`https://www.facebook.com/sharer/sharer.php?u=https://www.genexa.com${path}`}
							openInNewWindow={true}
						>
							<span className="blog-share-icon blog-share-icon--facebook"></span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
