import React from 'react';
import Seo from '../components/Seo';
import Link from '../components/Link';
import BlogComponentList from '../components/BlogComponentList';
import BlogSources from '../components/BlogSources';
import BlogLatestPosts from '../components/BlogLatestPosts';
import RelatedBlogPosts from '../components/RelatedBlogPosts';
import BlogShare from '../components/BlogShare';

export default ({pageContext, location}) => {
	const {seo = {}, postDate, slug, author = {}, category = {}, components = [], sources = [], relatedBlogPosts = [], blogLatestPosts = [], hideFromSearchEngine} = pageContext;

	const metaTitle = seo.metaTitle || `${pageContext.title} - Genexa`;
	const openGraphTitle =
		seo.openGraphTitle || `${pageContext.title} - Genexa`;
	const twitterTitle = seo.twitterTitle || `${pageContext.title} - Genexa`;

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
			/>

			<BlogComponentList author={author} category={category} components={components} date={postDate} />

			{sources.length > 0 &&
				<BlogSources sources={sources} />
			}

			<div className="grid-container contained">
				<div className="row align--center mt5">
					<div className="col c12 c10--lg">
						<hr />
					</div>
				</div>
			</div>

			<BlogShare path={location.pathname} />

			<RelatedBlogPosts posts={relatedBlogPosts} />

			<BlogLatestPosts posts={blogLatestPosts} currentPostSlug={slug} />

		</React.Fragment>
		
	);
};
