import React from 'react';
import Link from '../components/Link';

export default ({
	sources = [],
}) => {
	return (
		<div className="grid-container contained">
			<div className="row align--center mt5">
				<div className="col c12 c10--lg">
					<div className="rich-text line-break">
						<h5 className="sans--md fw--700 mb1">Sources:</h5>
						{sources.map((url, index) => (
							<div key={index} className="rich-text line-break mb1">
								<Link
									aria-label="Source"
									url={url}
									openInNewWindow={true}
								>
									{url}
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
