import React from 'react';
import Link from './Link';

export default ({
	_key,
	currentPostSlug,
	posts = [],
}) => {
	const filteredPosts = posts.filter(post => post.slug !== currentPostSlug);

	if (filteredPosts.length > 20) {
		filteredPosts.length = 20;
	}

	return (
		<div className="grid-container contained">
			<div className="row align--center mt4">
				<div className="col c12 c10--lg">
					<div className="rich-text line-break">
						<h4 className="sans--lg fw--700">All Articles</h4>
					</div>
				</div>
			</div>

			<div className="row align--center mt6">
				{filteredPosts.map(({_id, title, slug, category = {}}, index) => (
					<div key={_id + '-' + index} className="blog-card col c12 c5--md mb8">
						<button
							title={category.title}
							aria-label={category.title}
							className="no-hover button--pill--secondary blog-category--primary"
							style={{
								'--category-color': category.color ? category.color : null,
							}}
						>
							{category.title}
						</button>
						<div className="line-break">
							<h2 className="mt3 mb2 serif--lg serif--xxl--md">
								<Link title={title} className="link--underline-small" url={`/blog/${slug}`}>
									{title}
								</Link>
							</h2>
							<Link title="Continue to Article" className="link--underline-invert-small" url={`/blog/${slug}`}>
								Continue to Article
							</Link>
						</div>
					</div>
				))}
				{filteredPosts.length < 2 &&
					<div className="col c12 c5--md"></div>
				}
			</div>

			<div className="row align--center mt5">
				<div className="col c12 c10--lg">
					<hr />
				</div>
			</div>
		</div>
	);
};
