import React from 'react';
import Link from '../components/Link';

export default ({
	_key,
	posts = [],
}) => {
	return (
		<div className="grid-container contained">
			<div className="row align--center mt10">
				<div className="col c12 c10--lg">
					<div className="rich-text line-break">
						<h4 className="sans--lg fw--700">Related Articles</h4>
					</div>
				</div>
			</div>

			<div className="row align--center mt6">
				{posts.map(({_id, title, slug, category = {}}, index) => (
					<div key={_id + '-' + index} className="blog-card col c12 c5--md mb8">
						<button
							title={category.title}
							aria-label={category.title}
							className="no-hover button--pill--secondary blog-category--primary"
							style={{
								'--category-color': category.color ? category.color : null,
							}}
						>
							{category.title}
						</button>
						<div className="line-break">
							<h2 className="mt3 mb2 serif--lg serif--xxl--md">
								<Link className="link--underline-small" url={`/blog/${slug}`} title={title}>
									{title}
								</Link>
							</h2>
							<Link className="link--underline-invert-small" url={`/blog/${slug}`} title="Continue to Article-">
								Continue to Article
							</Link>
						</div>
					</div>
				))}
				{posts.length < 2 &&
					<div className="col c12 c5--md"></div>
				}
			</div>
		</div>
	);
};
